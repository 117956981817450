import { useActiveRound, useCurrentRound, useWorkoutSession } from 'store/workout/selectors'
import useRoundComplete from 'lib/hooks/useRoundComplete'
import { roundIdToLocalStorage } from 'lib/workoutData'
import { getRoundStats, getWorkoutDuration } from 'lib/stats'
import { checkIfRoundHasChallenges } from 'lib/challenges'
import Main from 'layout/main'
import StatsRoundComplete from 'components/stats/stats-round-complete'
import Header from 'components/header/header'
import HeaderActive from 'components/header/active'
import ForceGraphRound from 'components/graph-rounds/force-graph-round'
import ChallengeFeedback from 'components/challenge/challenge-feedback'
import { RoundCompleteFooter } from 'components/rounds-fragments/round-complete-footer'
import useCountdown from 'lib/hooks/useCountdown'
import { useNextStep } from 'lib/hooks/useNextStep'

// Displaying results of the round that just finished
const RoundComplete = () => {
  const { roundId } = useRoundComplete()
  const nextStep = useNextStep()

  const handleNextStep = () => {
    // console.log(roundId)
    // if (roundId) {
    nextStep();
    // } else {
    //   console.log('No roundId available.');
    // }
  };

  const workoutSession = useWorkoutSession()
  const activeRound = useActiveRound()
  const currentRound = useCurrentRound()
  const { maxForce, duration } = getRoundStats(currentRound)

  const timeLeft = getWorkoutDuration({ ...workoutSession, rounds: workoutSession.rounds.slice(activeRound - 1) })
  const hasChallenges = checkIfRoundHasChallenges(currentRound)

  roundIdToLocalStorage(roundId)
  useCountdown({ initCount: currentRound.breakSeconds, cb: handleNextStep })

  const renderGraphChallenge = hasChallenges ? (
    <ChallengeFeedback />
  ) : (
    <ForceGraphRound roundData={currentRound} maxForce={maxForce} roundTime={duration} roundComplete={true} />
  )

  return (
    <Main className="nextround">
      <div className="component RoundComplete">
        <Header title={<h1>{`Round ${activeRound + 1} completed`}</h1>} />
        <HeaderActive value={timeLeft} text="Time left" />
        <div className="row RoundComplete__stats">
          <div className="col-3">
            <StatsRoundComplete />
          </div>
          <div className="col-9">
            <div className="RoundComplete__chart">{renderGraphChallenge}</div>
          </div>
        </div>
        <div className="row col-12">
          <RoundCompleteFooter initCount={currentRound.breakSeconds} />
        </div>
      </div>
    </Main>
  )
}

export default RoundComplete
